import { useEffect, useState } from "react";
import "./assets/styles/HomePage.css";
import StartAnimation from "./components/StarAnimation";
import Swal from "sweetalert2";
import Congrat from "./components/congrat/Congrat";
import sunrise from "./assets/images/sunrise.png";
import bottom from "./assets/images/bottom.png";
import back_home from "./assets/images/icon-back.png";
import { information } from "./const/info.const";
import {
  addPointClient,
  getCaptchaTokenClient,
  getCodeClient,
} from "./services/api.service";
import moment from "moment-timezone";

const HomePage = () => {
  const [promoCode, setPromoCode] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [warningMessage, setWarningMessage] = useState("");
  const [captchaBase64, setCaptchaBase64] = useState("");
  const [site, setSite] = useState(information.site);

  useEffect(() => {
    // if (information.f8bet.includes(window.location.hostname)) {
    document
      .getElementsByClassName("logo")[0]
      .setAttribute("src", "/images/logo_f8bet.png");
    document
      .getElementsByClassName("text-img")[0]
      .setAttribute("src", "/images/text_f8bet.png");
    document.getElementById("home-page").classList.add("bg-f8bet");
    document
      .getElementsByClassName("input-cont")[0]
      .classList.add("input-cont-f8bet");
    document.getElementById("submit-btn").classList.add("submit-btn-f8bet");
    localStorage.setItem("site-redeem", "f8bet");
    setSite("f8bet");
    document.getElementsByClassName("character-pic")[0].style.display = "none";
  }, [site]);

  useEffect(() => {
    if (captchaBase64) {
      document.getElementById("captcha-image").src = captchaBase64;
    }
  }, [captchaBase64]);

  const handleCheckCode = async () => {
    if (!promoCode.trim()) {
      setWarningMessage("Mã Code không được bỏ trống");
      return;
    }
    let valueCode = promoCode.trim();
    Swal.fire({
      titleText: "Đang kiểm tra ...",
      color: "#ffffff",
      allowOutsideClick: false,
      customClass: "popup-captcha-token",
      background: `linear-gradient(rgb(106 183 255) 48%, rgb(186 214 255) 82%)`,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      let loadCaptcha = await getCaptchaTokenClient();
      setCaptchaBase64(loadCaptcha.captchaUrl);
      document.cookie = `token=${loadCaptcha.token}; max-age=180; path=/`;
      let tokenCaptcha = loadCaptcha.token;
      Swal.fire({
        title: "Mã Xác Thực",
        showConfirmButton: false,
        allowOutsideClick: false,
        customClass: "popup-captcha-token",
        html: `
        <div class="input-form-captcha-wrapper">
          <div class="thumb-captcha">
            <img id="captcha-image" class="image-captcha notranslate" src="" alt="Captcha Image">
          </div>
          <div class="input-form-captcha">
            <input class="notranslate" translate="no" type="text" placeholder="Nhập mã captcha" id="captcha-input">
            </input>
          </div>
        </div>
        <button id="verify-captcha" class="swal2-confirm swal2-styled">Xác Thực</button>
      `,
      });

      document.getElementById("captcha-image").src = loadCaptcha.captchaUrl;
      document
        .getElementById("captcha-image")
        .addEventListener("click", async () => {
          let newCaptcha = await getCaptchaTokenClient();
          setCaptchaBase64(newCaptcha.captchaUrl);
          tokenCaptcha = newCaptcha.token;
          document.cookie = `token=${newCaptcha.token}; max-age=180; path=/`;
          document.getElementById("captcha-image").src = newCaptcha.captchaUrl;
        });

      document
        .getElementById("verify-captcha")
        .addEventListener("click", async () => {
          const captchaInputValue =
            document.getElementById("captcha-input").value;
          console.log("clientTokenclientTokenclientToken", tokenCaptcha);
          let codeResult = await getCodeClient(
            valueCode,
            captchaInputValue,
            tokenCaptcha
          );
          if (codeResult.status_code === 403) {
            Swal.fire({
              icon: "error",
              allowOutsideClick: false,
              title: codeResult.title_mess,
              background: "none",
              html:
                "<div class='modal-text-wrapper'>" +
                "<div class='modal-text-cont'>" +
                "<p class='modal-text'>Mã Code: &nbsp;</p>" +
                "<p style='font-weight: 600'>" +
                codeResult.detail.promo_code +
                "</p>" +
                "</div>" +
                "<div class='modal-text-cont'>" +
                "<p class='modal-text'>Điểm thưởng: &nbsp;</p>" +
                "<p style='font-weight: bold; color:#ec164c'>" +
                codeResult.detail.point +
                "&nbsp; điểm</p>" +
                "</div>" +
                "</div>",
              footer:
                '<a class="cskh" href="' +
                information?.cskh_url +
                '" target="_blank">Chăm sóc khách hàng 24/7</a>',
            }).then(() => {
              window.location.reload();
            });
          } else if (codeResult.status_code === 400) {
            Swal.fire({
              icon: "error",
              title: "Lỗi xác thực",
              text: "Mã captcha không đúng. Vui lòng thao tác lại.",
            });
            return;
          } else {
            if (codeResult.valid === false) {
              Swal.fire({
                icon: "warning",
                title: codeResult.title_mess,
                text: codeResult.text_mess,
                confirmButtonText: "Thử lại",
                footer:
                  '<a href="' +
                  information?.cskh_url +
                  '" target="_blank">Chăm sóc khách hàng 24/7.</a>',
              }).then(() => {
                window.location.reload();
              });
            } else if (codeResult.valid === true) {
              let time = moment(codeResult.detail.time)
                .tz("Asia/Ho_Chi_Minh")
                .format("DD/MM/YYYY, H:mm:ss");
              const { value: input_data } = await Swal.fire({
                allowOutsideClick: false,
                html: `
                <div style="display: flex; align-items: center; justify-content: center; margin-bottom: 8px;">
                  <div class="outer-circle">
                    <div class="inner-circle">
                      <div class="checkmark"></div>
                    </div>
                  </div>
                </div>
                <h2>${codeResult.title_mess}</h2>
                <table style="width: 100%; text-align: left;">
                  <tbody>
                    <tr>
                      <td>Mã Code:</td>
                      <td class="align-right"><strong>${codeResult?.detail?.promo_code}</strong></td>
                    </tr>
                    <tr>
                      <td>Điểm thưởng:</td>
                      <td class="pointbonus align-right"><strong>${codeResult?.detail?.point} điểm </strong></td>
                    </tr>
                    <tr>
                      <td>${codeResult?.detail?.mess}:</td>
                      <td class="align-right">${time}</td>
                    </tr>
                  </tbody>
                </table>
              `,
                footer:
                  '<a href="' +
                  information?.cskh_url +
                  '" target="_blank">Chăm sóc khách hàng 24/7</a>',
                input: "text",
                inputLabel: "Vui lòng nhập tên tài khoản để nhận thưởng",
                inputPlaceholder: "Tên tài khoản",
                showCancelButton: true,
                cancelButtonText: "Hủy",
                confirmButtonText: "Xác nhận",
                inputValidator: async (value) => {
                  if (!value) {
                    return "Vui lòng nhập tên tài khoản!";
                  }
                },
              });
              if (input_data) {
                Swal.fire({
                  text: "Đang cộng điểm ...",
                  allowOutsideClick: false,
                  width: 300,
                  didOpen: () => {
                    Swal.showLoading();
                  },
                });
                let addPoint = await addPointClient(
                  input_data,
                  codeResult.detail.promo_code
                );
                if (addPoint.status_code === 502) {
                  Swal.fire({
                    icon: "warning",
                    title: addPoint.title_mess,
                    text: addPoint.text_mess,
                    footer:
                      '<a href="' +
                      information?.cskh_url +
                      '" target="_blank">Chăm sóc khách hàng 24/7.</a>',
                  }).then(() => {
                    window.location.reload();
                  });
                } else {
                  if (addPoint.valid === false) {
                    Swal.fire({
                      icon: "error",
                      title: addPoint.title_mess,
                      text: addPoint.text_mess,
                      footer:
                        '<a href="' +
                        information?.cskh_url +
                        '" target="_blank">Chăm sóc khách hàng 24/7.</a>',
                    }).then(() => {
                      window.location.reload();
                    });
                  } else if (addPoint.valid === true) {
                    await Swal.fire({
                      html: `
                      <div style="display: flex; align-items: center; justify-content: center; margin-bottom: 8px;">
                        <div class="outer-circle">
                          <div class="inner-circle">
                            <div class="checkmark"></div>
                          </div>
                        </div>
                      </div>
                      <h2>Chúc mừng</h2>
                      <p>
                        Chúc mừng <strong class="pointbonus">${addPoint?.player_id}</strong> đã nhận thành công 
                        <span style="color: red; font-weight: bold;">${addPoint?.point} điểm</span>
                      </p>
                      <div class="pyro" id="pyro">
                        <div class="before"></div>
                        <div class="after"></div>
                        <div class="before"></div>
                        <div class="after"></div>
                        <div class="before"></div>
                        <div class="after"></div>
                      </div>
                  `,
                      showCancelButton: false,
                      confirmButtonText: "TIẾP TỤC",
                      customClass: {
                        confirmButton: "popup-button confirm",
                      },
                      allowOutsideClick: false,
                    }).then(() => {
                      window.location.reload();
                    });
                  }
                }
              }
            }
          }
        });
    } catch (error) {
      console.error("Error fetching code:", error);
    }
    //  finally {
    //   Swal.close(); // Ensure Swal is closed after the operation
    // }
  };

  return (
    <div id="home-page">
      <div className="back-home-container">
        <a
          href="javascript:void(0)"
          title="Trang chủ F8BET"
          className="back-home-link"
          onClick={(e) => {
            e.preventDefault();
            if (document.referrer) {
              window.history.back();
            } else {
              window.location.href = "https://f8beta2.com/Promotion";
            }
          }}
        >
          <img src={back_home} className="back-home-img" alt="Trở lại" />
        </a>
      </div>
      <div className="warpper">
        <img className="logo" alt="" />
        <img className="text-img" alt="" />
        <div className="box-img">
          <div className="input-cont">
            <label className="box-label">NHẬP CODE ĐỂ NHẬN THƯỞNG</label>
            <div className="input-item-cont">
              <input
                id="code-id"
                type="text"
                value={promoCode}
                onChange={(e) => setPromoCode(e.target.value)}
                placeholder="Vui lòng nhập mã CODE"
                autoComplete="off"
              ></input>
            </div>
            <div onClick={handleCheckCode} id="submit-btn" click-id="false">
              KIỂM TRA NGAY
            </div>
          </div>
        </div>
      </div>
      <StartAnimation />
      <Congrat />
      <img src={sunrise} className="sunrise" alt="" />
      <img src={bottom} className="character-pic" alt="" />
    </div>
  );
};

export default HomePage;